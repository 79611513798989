import {BOOLEAN, NUMBER, STRING} from "../../../components/common/form/helper/formConstants";

/**
 * Поля формы породы
 */
export const BREED_ID = "breedId";
export const BREED_TRANSLATION_ID = "translationId";
export const BREED_TITLE = "title";
export const BREED_APPEARANCE_ID = "appearanceId";
export const HEALTH_ID = "healthId";
export const BREED_FACTS = "breedFacts";
export const SOURCES = "sources";
export const BREED_HISTORY = "breedHistory";
export const SIZE_FROM = "sizeFrom";
export const SIZE_TO = "sizeTo";
export const WEIGHT_FROM = "weightFrom";
export const WEIGHT_TO = "weightTo";
export const LIFE_FROM = "lifeFrom";
export const LIFE_TO = "lifeTo";
export const CARE = "care";
export const BEHAVIOUR = "behaviour";

export const BORROWED_OBJECT = 'borrowedObject'
export const FACT = 'fact'
export const SOURCE_LINK = 'sourceLink'
export const LINK_DESCRIPTION = 'linkDescription'
export const BREED_COUNTRY_ID = 'breedCountryId'
export const BREED_IS_TECHNICAL = 'breedIsTechnical'

export const BREED_METADATA = {
    title: 'page.breed.title',
    backControllerName: 'breeds',
    titlePrepositionalCase: 'page.breed.titlePrepositionalCase',
    fields: [
        {title: BREED_ID, type: NUMBER},
        {title: BREED_TRANSLATION_ID, type: NUMBER},
        {title: BREED_TITLE, type: STRING},
        {title: BREED_COUNTRY_ID, type: NUMBER},
        {title: BREED_HISTORY, type: STRING},
        {title: BREED_APPEARANCE_ID, type: NUMBER},
        {title: SIZE_FROM, type: NUMBER},
        {title: SIZE_TO, type: NUMBER},
        {title: WEIGHT_FROM, type: NUMBER},
        {title: WEIGHT_TO, type: NUMBER},
        {title: HEALTH_ID, type: NUMBER},
        {title: LIFE_FROM, type: NUMBER},
        {title: LIFE_TO, type: NUMBER},
        {title: CARE, type: STRING},
        {title: BEHAVIOUR, type: STRING},
        {title: BREED_FACTS, type: STRING},
        {title: SOURCES, type: STRING},
        {title: BREED_IS_TECHNICAL, type: BOOLEAN}
    ]
}