import React from 'react';
import TableNoResults from "../../../components/common/table/TableNoResults";
import {useTranslation} from "react-i18next";
import Spinner from "../../../components/common/Spinner";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import BreedCharacteristic from "./BreedCharacteristic";
import ArticleWithHeader from "../../../components/common/ui/text/ArticleWithHeader";
import SourceLinks from "../../../components/common/ui/text/SourceLinks";
import {useParams} from "react-router-dom";
import BreedTitle from "./BreedTitle";
import {hasValue} from "../../../app/helper/commonHelper";

/**
 * Компонент, отвечающий за отображение информации о породе животного
 */
const BreedInfo = ({breedInfo, image, errorMessage, isLoading, isImgLoading}) => {
    const params = useParams();
    const {t} = useTranslation()
    const hasImage = Object.keys(image).length !== 0;

    if (isLoading || errorMessage !== '') {
        return <TableNoResults title={t("page.breed.infoNotFound")}/>;
    }

    return (
        <div>
            {isImgLoading ?
                <Spinner/> :
                hasImage ?
                    <div className="breed-photo__wrap-around">
                        <div className="breed-photo">
                            <img src={image} alt=""/>
                        </div>
                    </div>
                    : null
            }

            <Breadcrumbs
                elements={[{page: t("page.breeds.title"), path: '/breeds'},
                    {page: breedInfo.translation.title, path: ''}]}/>
            <BreedTitle title={breedInfo.translation.title} breedId={params.id} hasImage={hasImage}/>


            <div className="breed-characteristics">
                {hasValue(breedInfo?.translation?.appearance) &&
                hasValue(breedInfo?.translation?.appearance?.weightFrom) &&
                hasValue(breedInfo?.translation?.appearance?.weightTo) ?
                    <BreedCharacteristic title={t("page.breed.weight")}
                                         from={breedInfo.translation.appearance.weightFrom}
                                         to={breedInfo.translation.appearance.weightTo}
                                         measure={t("measure.kg")}/> : null
                }
                {hasValue(breedInfo?.translation?.health) &&
                hasValue(breedInfo?.translation?.health?.lifeFrom) &&
                hasValue(breedInfo?.translation?.health?.lifeTo) ?
                    <BreedCharacteristic title={t("page.breed.age")}
                                         from={breedInfo.translation.health.lifeFrom}
                                         to={breedInfo.translation.health.lifeTo} measure={t("measure.y")}/> : null
                }
                {hasValue(breedInfo?.translation?.appearance) &&
                hasValue(breedInfo?.translation?.appearance?.sizeFrom) &&
                hasValue(breedInfo?.translation?.appearance?.sizeTo) ?
                    <BreedCharacteristic title={t("page.breed.size")}
                                         from={breedInfo.translation.appearance.sizeFrom}
                                         to={breedInfo.translation.appearance.sizeTo}
                                         measure={t("measure.sm")}/> : null
                }
            </div>
            {hasValue(breedInfo.translation.breedFacts) ?
                <div className="breed-facts">
                    {breedInfo.translation.breedFacts?.map((item,index) =>
                        <div key={index} className="breed-facts__fact"><p>{item.fact}</p></div>
                    )}
                </div>
                : null
            }

            {hasValue(breedInfo?.country?.translation?.title) ?
                <ArticleWithHeader title={t("page.breed.section.origin")}
                                   text={breedInfo.country.translation.title}/> : null}

            {hasValue(breedInfo?.translation.breedHistory) ?
                <ArticleWithHeader title={t("page.breed.section.breedHistory")}
                                   text={breedInfo?.translation.breedHistory}/> : null}
            {hasValue(breedInfo?.translation.behaviour) ?
                <ArticleWithHeader title={t("page.breed.section.behaviour")}
                                   text={breedInfo?.translation.behaviour}/> : null}
            {hasValue(breedInfo?.translation.care) ?
                <ArticleWithHeader title={t("page.breed.section.care")}
                                   text={breedInfo?.translation.care}/> : null}
            {hasValue(breedInfo?.translation.sources) ?
                <SourceLinks sources={breedInfo?.translation.sources}/> : null}
        </div>
    );
};

export default BreedInfo;