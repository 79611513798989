import {BREED_COUNTRY_ID, BREED_FACTS, BREED_IS_TECHNICAL, SOURCES} from "./breedFormConstants";
import * as yup from "yup";
import {validateNameRegEx} from "../../../utils/stringutils";
import BreedService from "../api/BreedService";
import {storeItem} from "../../../components/common/form/helper/formHelper";
import {isEmptyOrNull} from "../../../app/helper/commonHelper";

/**
 * Валидация формы с породами
 */
export function formBreedSchema(t) {
    return yup.object().shape({
        title: yup.string().required(t("form.common.errors.fieldRequired")).matches(validateNameRegEx),
        sizeFrom: yup.number().transform((value) => (isNaN(value) ? 0 : value)).min(0),
        sizeTo: yup.number().transform((value) => (isNaN(value) ? 0 : value)).nullable()
            .min(yup.ref("sizeFrom"), t("form.common.errors.valueGreaterOrEquals")), // больше или равно
        weightFrom: yup.number().transform((value) => (isNaN(value) ? 0 : value)).min(0),
        weightTo: yup.number().transform((value) => (isNaN(value) ? 0 : value)).positive().nullable()
            .min(yup.ref("weightFrom"), t("form.common.errors.valueGreaterOrEquals")), // больше или равно
        lifeFrom: yup.number().transform((value) => (isNaN(value) ? 0 : value)).min(0),
        lifeTo: yup.number().transform((value) => (isNaN(value) ? 0 : value)).positive().nullable()
            .min(yup.ref("lifeFrom"), t("form.common.errors.valueGreaterOrEquals")), // больше или равно
        breedFacts: yup.array()
            .of(
                yup.object().shape({
                    fact: yup.string().ensure().required(t("form.common.errors.fieldRequired"))
                })
            ),
        sources: yup.array()
            .of(
                yup.object().shape({
                    borrowedObject: yup.string().ensure().required(),
                    linkDescription: yup.string().ensure().required()
                })
            )
    });
}

/**
 * Заполнение дто данными перед отправкой на бэк
 */
export function formDto(data, language, animalKind) {
    const dto = {
        id: data.breedId,
        language: language,
        animalKind: animalKind,
        country: {
            id: sessionStorage.getItem(BREED_COUNTRY_ID)
        },
        technical: data[BREED_IS_TECHNICAL],
        //Заполнение по умолчанию. Далее мы посмотрим нужно ли обновлять картинку
        //Если происходит update, то данные возьмем из пришедшей dto
        filename: "",
        translation: {
            id: data.translationId,
            locale: language,
            title: data.title === '' ? null : data.title,
            breedHistory: data.breedHistory === '' ? null : data.breedHistory,
            behaviour: data.behaviour === '' ? null : data.behaviour,
            care: data.care === '' ? null : data.care,
            breedFacts: data.breedFacts,
            sources: data.sources
        }
    };
    formHealth(dto, data);
    formAppearance(dto, data);
    nullIdsForNewFactsAndSources(dto);
    return dto;
}

/**
 * Для только что добавленных записей мы зануляем id,
 * чтобы не было проблем при поиске и сохранении сущности на бэке
 * здесь же айдишник нам нужен для того, чтобы мы могли итерироваться по списку
 */
function nullIdsForNewFactsAndSources(dto) {

    dto.translation.breedFacts?.forEach(fact => {
        if (typeof fact.id === 'string' || fact.id instanceof String) {
            fact.id = 0
        }
    })

    dto.translation.sources?.forEach(source => {
        if (typeof source.id === 'string' || source.id instanceof String) {
            source.id = 0
        }
    })
}

function formHealth(dto, data) {
    if (data.lifeFrom === 0 && data.lifeTo === 0) {
        return
    }
    dto.translation.health = {
        id: data.healthId,
        lifeFrom: data.lifeFrom === 0 ? null : data.lifeFrom,
        lifeTo: data.lifeTo === 0 ? null : data.lifeTo
    }
}

function formAppearance(dto, data) {
    if (data.sizeFrom === 0 && data.sizeTo === 0 && data.weightFrom === 0 && data.weightTo === 0) {
        return
    }
    dto.translation.appearance = {
        id: data.appearanceId,
        sizeFrom: data.sizeFrom === 0 ? null : data.sizeFrom,
        sizeTo: data.sizeTo === 0 ? null : data.sizeTo,
        weightFrom: data.weightFrom === 0 ? null : data.weightFrom,
        weightTo: data.weightTo === 0 ? null : data.weightTo
    }
}

/**
 * Создает скелет объекта для заимствованной информации
 */
export const addSourceLink = async (sourceIds, sourceLinks, setSourceLinks) => {
    setSourceLinks([...sourceLinks,
        {
            id: sourceIds.current,
            borrowedObject: "",
            sourceLink: "",
            linkDescription: ""
        }])
    sourceIds.current = sourceIds.current + 1;
}


/**
 * Инициализация заимствованной информации данными, пришедшими с бэка
 */
export const initSourceLinks = (sourceIds, setSourceLinks, data) => {
    if (data === null || data === undefined || data.length === 0) {
        return;
    }

    setSourceLinks(data)
    sourceIds.current = getEntityNextId(data);
}

export function initCheckBoxes(checkboxes) {
    if (isEmptyOrNull(checkboxes)) {
        return;
    }
    checkboxes.map(checkbox => storeItem(checkbox.fieldName, checkbox.value))
}

/**
 * Создает скелет объекта объект Фактов
 */
export const addFact = async (factsIds, breedFacts, setBreedFacts) => {
    setBreedFacts([...breedFacts,
        {
            id: factsIds.current,
            fact: ""
        }])
    factsIds.current = factsIds.current + 1;
}

/**
 * Инициализация фактов данными, пришедшими с бэка
 */
export const initFacts = (factsIds, setBreedFacts, data = []) => {
    if (data === null || data === undefined || data.length === 0) {
        return;
    }

    setBreedFacts(data)
    factsIds.current = getEntityNextId(data);
}

//получить следующий идентификатор для элемента - массива, сохраняемой в sessionStorage
export function getEntityNextId(entity) {
    if (entity.length === 0) {
        return 0;
    }
    let lastId = Math.max.apply(Math, entity.map(function (o) {
        return o.id;
    }))
    return lastId + 1;
}

/**
 * Факты
 */
export function getStoredFacts(setValue) {
    const storedFacts = JSON.parse(sessionStorage.getItem(BREED_FACTS));
    if (storedFacts === null || storedFacts.length === 0) {
        return [];
    }
    setValue(BREED_FACTS, storedFacts)
    return storedFacts;
}

//удалить поля формы, зачистить соответствующую запись в sessionStorage и пересетить
//оставшиеся поля в таблице
export function removeFact(index, getValues, setValue, setBreedFacts, toStore) {
    //получаем значение с формы
    const {breedFacts} = getValues();
    //фильтруем - нам нужно удалить элемент с порядковым номером
    let newFacts = [...breedFacts].filter((el, idx) => idx !== Number(index))
    setBreedFacts(newFacts);
    if (toStore) {
        sessionStorage.setItem(BREED_FACTS, JSON.stringify(newFacts))
    }
    setValue(BREED_FACTS, newFacts)
}

/**
 * Источник информации
 */

//получить сохраненные в sessionStorage записи
//вернуть их и также просетить в setValue хука useForm
export function getStoredSources(setValue) {
    const storedSources = JSON.parse(sessionStorage.getItem(SOURCES));
    if (storedSources === null || storedSources.length === 0) {
        return [];
    }
    setValue(SOURCES, storedSources)
    return storedSources;
}

//удалить поля формы, зачистить соответствующую запись в sessionStorage и пересетить
//оставшиеся поля в таблице
export function removeSourceLink(index, getValues, setValue, setSourceLinks, toStore) {
    //получаем значение с формы
    const {sources} = getValues();
    //фильтруем - нам нужно удалить элемент с порядковым номером
    let newLinks = [...sources].filter((el, idx) => idx !== Number(index))
    setSourceLinks(newLinks);
    if (toStore) {
        sessionStorage.setItem(SOURCES, JSON.stringify(newLinks))
    }
    setValue(SOURCES, newLinks)
}

/**
 * Получить данные о породе по id
 *
 * @param breedId id породы
 * @param i18n для выбора языка
 * @param setBreed сеттер породы
 * @param setErrorMessage сеттер сообщения об ошибке
 */
export function getBreedInfo(breedId, i18n, setBreed, setErrorMessage) {
    if (breedId === null || breedId === undefined || breedId === 0) {
        return;
    }
    const response = BreedService.getById(breedId, i18n.language);

    response.then((resp) => {
        const data = resp.data.data;
        setBreed(data)
    }, (error) => {
        setErrorMessage(error.response?.data?.messages ?
            error.response?.data?.messages?.ERROR[0] : error.message)
    })
}
