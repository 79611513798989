import React, {useEffect, useRef, useState} from 'react';
import AnimalKindSelector from "../AnimalKindSelector";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import FormItem from "../../../../components/common/form/parts/inputs/wrappers/FormItem";
import {
    BEHAVIOUR,
    BREED_APPEARANCE_ID,
    BREED_COUNTRY_ID,
    BREED_FACTS,
    BREED_HISTORY,
    BREED_ID,
    BREED_IS_TECHNICAL,
    BREED_METADATA,
    BREED_TITLE,
    BREED_TRANSLATION_ID,
    CARE,
    HEALTH_ID,
    LIFE_FROM,
    LIFE_TO,
    SIZE_FROM,
    SIZE_TO,
    SOURCES,
    WEIGHT_FROM,
    WEIGHT_TO
} from "../../helpers/breedFormConstants";
import {
    formBreedSchema,
    formDto,
    getEntityNextId,
    getStoredFacts,
    getStoredSources,
    initFacts,
    initSourceLinks
} from "../../helpers/breedHelper";
import {
    clearSessionDataForEntity,
    getStoredItem,
    isFieldFilled,
    storeItem
} from "../../../../components/common/form/helper/formHelper";
import FormImage from "../../../../components/common/form/parts/inputs/wrappers/FormImage";
import HiddenInput from "../../../../components/common/form/parts/inputs/HiddenInput";
import {
    ACTION_ADD,
    ACTION_UPDATE,
    BOOLEAN,
    FILES_MAX_NUMBER,
    NUMBER,
    STRING
} from "../../../../components/common/form/helper/formConstants";
import BreedService from "../../api/BreedService";
import {useNavigate} from "react-router-dom";
import {getLanguageShortName} from "../../../../utils/langUtils";
import {CAT, EMPTY} from "../../../../app/const/appConst";
import {useSelector} from "react-redux";
import Form from "../../../../components/common/form/abstract/Form";
import SubmitSection from "../../../../components/common/form/parts/controlsection/SubmitSection";
import TextAreaFormItem from "../../../../components/common/form/parts/inputs/wrappers/TextAreaFormItem";
import TextInputFormItem from "../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import {hasValue} from "../../../../app/helper/commonHelper";
import RangeFormItem from "../../../../components/common/form/parts/inputs/wrappers/RangeFormItem";
import BreedFactsFormElement from "./BreedFactsFormElement";
import BreedSourceLinksFormElement from "./BreedSourceLinksFormElement";
import {getDictionaryRecordsAsOptions} from "../../../../service/dictionaryService";
import {COUNTRY_METADATA} from "../../../adminpanel/dictionaries/geo/geoFormsConstants";
import breedInfo from "../BreedInfo";
import TextInputWithOptionsFormItem
    from "../../../../components/common/form/parts/inputs/wrappers/TextInputWithOptionsFormItem";
import CheckboxFormItem from "../../../../components/common/form/parts/inputs/wrappers/CheckboxFormItem";

/**
 * Форма добавления породы животного
 * action = ADD или UPDATE - чтобы разделить логику форм
 */
const BreedForm = ({action, breed = {}, image = {}}) => {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const token = useSelector(state => state.userReducer.token);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(false);

    const {
        register,
        formState: {errors},
        handleSubmit,
        watch,
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(formBreedSchema(t)),
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    const [animalKind, setAnimalKind] = useState(action === ACTION_ADD ? CAT : breed.animalKind)
    const language = getLanguageShortName(i18n.language);
    const [images, setImages] = useState([]);
    const [updateImage, setUpdateImage] = useState(false)
    const [sourceLinks, setSourceLinks] = useState([]);
    const [breedFacts, setBreedFacts] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [countries, setCountries] = useState([]);
    const sourceIds = useRef(action === ACTION_ADD ? getEntityNextId(sourceLinks) : 0);
    const factsIds = useRef(action === ACTION_ADD ? getEntityNextId(breedFacts) : 0);

    /**
     * Инит полей формы при добавлении породы
     * просечивает данные из стора для простых полей (не массивов)
     */
    function initAdd() {
        setValue(BREED_ID, getStoredItem(BREED_ID, NUMBER))
        setValue(BREED_TRANSLATION_ID, getStoredItem(BREED_TRANSLATION_ID, NUMBER))
        setValue(BREED_APPEARANCE_ID, getStoredItem(BREED_APPEARANCE_ID, NUMBER))
        setValue(HEALTH_ID, getStoredItem(HEALTH_ID, NUMBER))
        setValue(BREED_TITLE, getStoredItem(BREED_TITLE, STRING))
        setValue(BREED_HISTORY, getStoredItem(BREED_HISTORY, STRING))
        setValue(SIZE_FROM, getStoredItem(SIZE_FROM, NUMBER))
        setValue(SIZE_TO, getStoredItem(SIZE_TO, NUMBER))
        setValue(WEIGHT_FROM, getStoredItem(WEIGHT_FROM, NUMBER))
        setValue(WEIGHT_TO, getStoredItem(WEIGHT_TO, NUMBER))
        setValue(LIFE_FROM, getStoredItem(LIFE_FROM, NUMBER))
        setValue(LIFE_TO, getStoredItem(LIFE_TO, NUMBER))
        setValue(CARE, getStoredItem(CARE, STRING))
        setValue(BEHAVIOUR, getStoredItem(BEHAVIOUR, STRING))
        //set isTechnical field
        setValue(BREED_IS_TECHNICAL, getStoredItem(BREED_IS_TECHNICAL, BOOLEAN))

        setBreedFacts(getStoredFacts(setValue));
        setSourceLinks(getStoredSources(setValue))
    }

    /**
     * Инит полей формы при обновлении породы
     * просечивает данные из БД
     */
    function initUpdate(breedInfo) {
        if (breedInfo === undefined) {
            return;
        }
        //заполнение объекта формы
        setValue(BREED_ID, breedInfo.id)
        setValue(BREED_TRANSLATION_ID, breedInfo.translation.id)
        setValue(BREED_APPEARANCE_ID, breed.translation.appearance?.id)
        setValue(HEALTH_ID, breed.translation.health?.id)
        setValue(BREED_TITLE, breedInfo.translation.title)
        setValue(BREED_HISTORY, breedInfo.translation.breedHistory)
        setValue(SIZE_FROM, breedInfo.translation.appearance?.sizeFrom)
        setValue(SIZE_TO, breedInfo.translation.appearance?.sizeTo)
        setValue(WEIGHT_FROM, breedInfo.translation.appearance?.weightFrom)
        setValue(WEIGHT_TO, breedInfo.translation.appearance?.weightTo)
        setValue(LIFE_FROM, breedInfo.translation.health?.lifeFrom)
        setValue(LIFE_TO, breedInfo.translation.health?.lifeTo)
        setValue(CARE, breedInfo.translation.care)
        setValue(BEHAVIOUR, breedInfo.translation.behaviour)
        setValue(SOURCES, breedInfo.translation.sources)
        setValue(BREED_FACTS, breedInfo.translation.breedFacts)
        setValue(BREED_IS_TECHNICAL, breedInfo.technical)

        //заполнение коллекций
        initFacts(factsIds, setBreedFacts, breedInfo.translation.breedFacts)
        initSourceLinks(sourceIds, setSourceLinks, breedInfo.translation.sources);
        if (image.data_url !== '') {
            setImages(Array.of(image))
        }
        if (hasValue(breedInfo.country)) {
            storeItem(BREED_COUNTRY_ID, breedInfo.country.id)
        }
    }

    /**
     * Получить список стран
     */
    function getCountriesOptions() {
        setIsLoading(true);
        const response = getDictionaryRecordsAsOptions(COUNTRY_METADATA.backControllerName);
        response.then((resp) => {
            setErrorMessage(EMPTY)
            setCountries(resp.data.data)
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });
    }

    /**
     * Получить первоначальное значение id в селект со списком
     */
    function getCountryInitialId() {
        const storedValue = getStoredItem(BREED_COUNTRY_ID, NUMBER);
        if (storedValue !== 0) {
            return storedValue;
        }

        return ACTION_ADD === action || !breedInfo || !breedInfo.country ?
            0 : breedInfo.country.id;
    }

    /**
     * Обработка события отправки формы
     */
    function submitForm(data) {
        setDisabledSubmit(true);
        setIsLoading(true)

        const dto = formDto(data, language, animalKind);
        // при обновлении данных о породе, чтобы не пересохранять данные об изображении
        // мы проверяем обновлялась ли она на UI
        if (updateImage === false && action === ACTION_UPDATE) {
            dto.filename = breed.filename;
        }

        const resp = BreedService.saveBreed(
            dto,
            updateImage,
            images.length === 0 ? null : images[0].file,
            token)
        resp.then(
            (resp) => {
                clearSessionDataForEntity(BREED_METADATA.fields)
                setError(false);
                setIsLoading(false);
                navigate(`/breeds/${resp.data.data.breedId}`);
            },
            (error) => {
                setIsLoading(false)
                setError(true)
                setDisabledSubmit(false);
                setErrorMessage(error.response?.data?.messages ?
                    error.response?.data?.messages?.ERROR[0] : error.message)
            })
    }

    useEffect(() => {
        //получаем countries
        getCountriesOptions();
        switch (action) {
            case ACTION_ADD :
                return initAdd();
            case ACTION_UPDATE :
                return initUpdate(breed);
            default:
                return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onImageChange(imageList) {
        setUpdateImage(true);
        setImages(imageList);
    }

    const allValues = watch();

    return (
        <Form handleSubmit={handleSubmit} submitForm={submitForm}>
            <HiddenInput fieldName={BREED_ID} register={register}/>
            <HiddenInput fieldName={BREED_TRANSLATION_ID} register={register}/>
            {/*<TranslationLanguageSelector language={language} setLanguage={setLanguage}/>*/}
            <FormItem>
                <AnimalKindSelector animalKind={animalKind} setAnimalKind={setAnimalKind}
                                    catTitle={t("form.newBreed.cat")} dogTitle={t("form.newBreed.dog")}/>
            </FormItem>

            <TextInputFormItem errors={errors} fieldName={BREED_TITLE} placeholder={t("form.newBreed.breedTitle")}
                               register={register} hasValue={hasValue(allValues[BREED_TITLE])}
                               tabIndex='1' onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

            <TextInputWithOptionsFormItem fieldName={BREED_COUNTRY_ID} options={countries}
                                          placeholder='Страна происхождения'
                                          register={register} tabIndex='2' setValue={setValue}
                                          initialId={getCountryInitialId()}/>

            <FormImage images={images} maxNumber={FILES_MAX_NUMBER} onChange={onImageChange}/>

            <TextAreaFormItem fieldName={BREED_HISTORY} placeholder={t("form.newBreed.breedHistory")}
                              register={register} tabIndex='3' action={action}
                              hasValue={allValues[BREED_HISTORY]}/>

            {/*следующие два диапазона относятся к сущности appearance*/}
            <HiddenInput fieldName={BREED_APPEARANCE_ID} register={register}/>

            <RangeFormItem title={t("form.newBreed.size")}
                           fieldNameFrom={SIZE_FROM} fieldNameTo={SIZE_TO}
                           hasValueFrom={hasValue(allValues[SIZE_FROM])} hasValueTo={hasValue(allValues[SIZE_TO])}
                           tabIndexFrom='4' tabIndexTo='5'
                           errors={errors} register={register}
                           onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

            <RangeFormItem title={t("form.newBreed.weight")}
                           fieldNameFrom={WEIGHT_FROM} fieldNameTo={WEIGHT_TO}
                           hasValueFrom={hasValue(allValues[WEIGHT_FROM])} hasValueTo={hasValue(allValues[WEIGHT_TO])}
                           tabIndexFrom='6' tabIndexTo='7' errors={errors} register={register}
                           onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

            {/*следующий диапазон относятся к сущности health */}
            <HiddenInput fieldName={HEALTH_ID} register={register}/>

            <RangeFormItem title={t("form.newBreed.life")}
                           fieldNameFrom={LIFE_FROM} fieldNameTo={LIFE_TO}
                           hasValueFrom={hasValue(allValues[LIFE_FROM])} hasValueTo={hasValue(allValues[LIFE_TO])}
                           tabIndexFrom='8' tabIndexTo='9' errors={errors} register={register}
                           onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

            <TextAreaFormItem fieldName={CARE} placeholder={t("form.newBreed.care")}
                              register={register} tabIndex='10' action={action}
                              hasValue={isFieldFilled(getValues, CARE)}/>

            <TextAreaFormItem fieldName={BEHAVIOUR} placeholder={t("form.newBreed.behaviour")}
                              register={register} tabIndex='11' action={action}
                              hasValue={isFieldFilled(getValues, BEHAVIOUR)}/>

            <BreedFactsFormElement factsIds={factsIds} breedFacts={breedFacts} setBreedFacts={setBreedFacts}
                                   action={action} errors={errors} register={register} getValues={getValues}
                                   setValue={setValue}/>

            <BreedSourceLinksFormElement sourceIds={sourceIds} sourceLinks={sourceLinks} setSourceLinks={setSourceLinks}
                                         register={register} errors={errors} action={action} getValues={getValues}
                                         setValue={setValue}/>

            {/*Видимость в списках*/}
            <CheckboxFormItem fieldName={BREED_IS_TECHNICAL} register={register}
                              checkboxValue={getValues(BREED_IS_TECHNICAL)}
                              putValueInStore={ACTION_ADD === action} getValues={getValues}
                              title={t("form.newBreed.isTechnical")} tabIndex='12' errors={errors}
                              additionalStyle='as-table-header'/>


            <SubmitSection hasFormError={error} errorMessage={errorMessage} isFormLoading={loading}
                           submitLabel={t("button.save")} isSubmitDisabled={disabledSubmit}/>
        </Form>
    );
};

export default BreedForm;