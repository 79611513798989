import * as yup from "yup";
import {validateNameRegEx} from "../../../../utils/stringutils";
import {addPetSteps, PET_FORM_VALIDATED_STEPS} from "./petFormConstants";
import {isDate, parse} from "date-fns";
import {getStoredItem, isEmptyStoreItem} from "../../../../components/common/form/helper/formHelper";
import {NUMBER, STRING} from "../../../../components/common/form/helper/formConstants";
import {hasValue} from "../../../../app/helper/commonHelper";
import {setActivePet} from "../../../../app/store/userReducer";
import UserService from "../../../../service/UserService";
import {getBreedInfo} from "../../../breeds/helpers/breedHelper";

/**
 * Исключить шаг формы из массива провалидированных шагов
 */
export function removeCurrentStepFromValidated(stepNumber) {
    let storedArr = JSON.parse(sessionStorage.getItem(PET_FORM_VALIDATED_STEPS));
    if (storedArr !== null && storedArr.includes(stepNumber)) {
        const stepIdx = storedArr.indexOf(stepNumber)
        storedArr.splice(stepIdx, 1);
        sessionStorage.setItem(PET_FORM_VALIDATED_STEPS, JSON.stringify(storedArr));
    }
}

/**
 * Валидация первого шага формы с добавлением питомца
 */
export function petFormStepOneSchema(t) {
    return yup.object().shape({
        petHomeName: yup.string().required(t("form.common.errors.fieldRequired")).matches(validateNameRegEx),
        petGender: yup.string().ensure().oneOf(["MALE", "FEMALE"], "Укажите пол питомца"),
        petBreed: yup.string().required(t("form.common.errors.fieldRequired"))
    });
}

/**
 * Валидация формы с кличкой питомца
 */
export function petNameFormSchema(t) {
    return yup.object().shape({
        petHomeName: yup.string().required(t("form.common.errors.fieldRequired")).matches(validateNameRegEx)
    });
}

/**
 * Валидация формы с датой рождения питомца
 */
export function petBirthDateFormSchema(t) {
    return yup.object().shape({
        petBirthDate: yup.date().nullable()
            .transform(parseDateString)
            .required(t("form.common.errors.fieldRequired"))
            .max(new Date(), "Указанная дата еще не наступила")
            .min(new Date('1800-01-01'), "Укажите корректную дату"),
    });
}

/**
 * Валидация второго шага формы с добавлением питомца
 */
export function petFormStepTwoSchema(t) {
    return yup.object().shape({
        petBirthDate: yup.date().nullable()
            .transform(parseDateString)
            .required(t("form.common.errors.fieldRequired"))
            .max(new Date(), "Указанная дата еще не наступила")
            .min(new Date('1800-01-01'), "Укажите корректную дату"),
        nutritionType: yup.string().ensure().oneOf(["INDUSTRIAL", "NATURAL", "MIXED"], 'Выберите один из вариантов'),
        isPetNeuter: yup.string().ensure().oneOf(["NEUTERED", "NOT_NEUTERED"], "Укажите статус стерилизации питомца")
    });
}

export function livingEnvironmentSchema() {
    return yup.object().shape({
        livingEnvironment: yup.string().ensure().oneOf(["CITY", "COUNTRYSIDE"], 'Выберите один из вариантов')
    });
}

export function livingConditionsSchema() {
    return yup.object().shape({
        livingConditions: yup.string().ensure().oneOf(["INDOOR", "OUTDOOR"], 'Выберите один из вариантов')
    });
}

export function petFormStepThreeSchema() {
    return yup.object().shape({
        livingEnvironment: yup.string().ensure().oneOf(["CITY", "COUNTRYSIDE"], 'Выберите один из вариантов'),
        livingConditions: yup.string().ensure().oneOf(["INDOOR", "OUTDOOR"], 'Выберите один из вариантов')
    });
}

export function petChipNumberFormSchema() {
    return yup.object().shape({
        chipNumber: yup.string()
            .test("onlyDigits", "В этом поле могут быть только цифры", val => val === undefined || val.length === 0 || /^[0-9]+$/.test(val))
            .test("fixedLength", "Должно быть 15 символов", val => val === undefined || val.length === 0 || val.length === 15)
    });
}

export function petFormStepFiveSchema() {
    return yup.object().shape({
        dewormingDate: yup.date().nullable()
            .transform(parseDateString)
            .notRequired()
            .max(new Date(), "Указанная дата еще не наступила")
            .min(new Date('1800-01-01'), "Укажите корректную дату"),
        fleaAndTickTreatmentDate: yup.date().nullable()
            .transform(parseDateString)
            .notRequired()
            .max(new Date(), "Указанная дата еще не наступила")
            .min(new Date('1800-01-01'), "Укажите корректную дату"),
        rabiesVaccinationDate: yup.date().nullable()
            .transform(parseDateString)
            .notRequired()
            .max(new Date(), "Указанная дата еще не наступила")
            .min(new Date('1800-01-01'), "Укажите корректную дату"),
        complexVaccinationDate: yup.date().nullable()
            .transform(parseDateString)
            .notRequired()
            .max(new Date(), "Указанная дата еще не наступила")
            .min(new Date('1800-01-01'), "Укажите корректную дату")
    });
}

/**
 * Возвращает на предыдущий шаг (для формы из нескольких шагов)
 */
export function stepBack(currentStep, stepsInfo, navigate) {
    const targetStep = currentStep - 1;
    if (targetStep < 1) {
        return;
    }
    const petStep = stepsInfo.filter(el => el.number === targetStep);
    if (petStep === null || petStep === undefined || petStep.length !== 1) {
        return;
    }
    navigate(petStep[0]?.path);
}

function parseDateString(value, originalValue) {
    if (originalValue === '') {
        return null;
    }
    const dateArr = originalValue.toString().split('-');
    if (dateArr.length === 3 && dateArr[0].length !== 4) {
        return parse('1700-05-24', "yyyy-MM-dd", new Date());
    }
    return isDate(originalValue)
        ? originalValue
        : parse(originalValue, "yyyy-MM-dd", new Date());
}

/**
 * Вызывается когда нужно перейти к следующему шагу формы.
 * Сохраняет номер текущего шага в массиве валидных шагов
 */
export function navigateToTheNextStep(currentStep, navigate, nextStepPath) {
    setCurrentStepValidated(currentStep);
    navigate(nextStepPath);
}

/**
 * Проверяет есть ли шаги формы, которые не прошли валидацию
 * @return true если есть проблемные шаги
 */
export function checkIfInvalidStepsExists(setInvalidStepsErrorMessage) {
    let storedArr = JSON.parse(sessionStorage.getItem(PET_FORM_VALIDATED_STEPS));
    if (storedArr === null) {
        setInvalidStepsErrorMessage("Кажется вы не заполнили предыдущие шаги формы");
        return true;
    }
    let invalidSteps = [];
    addPetSteps.forEach(formStep => {
        if (!storedArr.includes(formStep.number)) {
            invalidSteps.push(formStep.number)
        }
    })
    if (invalidSteps.length > 0) {
        setInvalidStepsErrorMessage("В следующих шагах допущены ошибки: " + invalidSteps.toString()
            + ". Для успешного сохранения данных о питомце необходимо, чтобы вся введенная информация была корректной");
        return true;
    }
    return false;
}

/**
 * Проставляет признак того, что текущий шаг формы валидный
 */
export function setCurrentStepValidated(currentStep) {
    let storedArr = JSON.parse(sessionStorage.getItem(PET_FORM_VALIDATED_STEPS));
    if (storedArr === null) {
        storedArr = []
    }
    if (!storedArr.includes(currentStep)) {
        storedArr.push(currentStep)
        sessionStorage.setItem(PET_FORM_VALIDATED_STEPS, JSON.stringify(storedArr));
    }
}

/**
 * Формирование dto перед отправкой на бэк
 */
export function formDto() {
    const dto = {
        id: 0,
        breedId: sessionStorage.getItem('petBreed'),
        name: sessionStorage.getItem('petHomeName'),
        petGender: sessionStorage.getItem('petGender'),
        livingEnvironment: sessionStorage.getItem('livingEnvironment'),
        livingConditions: sessionStorage.getItem('livingConditions'),
        birthDate: sessionStorage.getItem('petBirthDate'),
        neuterInfo: {
            id: 0,
            isNeutered: sessionStorage.getItem('isPetNeuter') === 'NEUTERED'
        },
        nutritionHistory: [
            {
                id: 0,
                nutritionType: sessionStorage.getItem('nutritionType')
            }
        ],
    };
    fillIdentificationInfo(dto)
    fillWeightInfoList(dto);
    fillThreatPreventionsBlock(dto);
    return dto;
}

function fillWeightInfoList(dto) {
    if (getStoredItem('petWeight', NUMBER) === 0) {
        return;
    }
    dto.weightInfoList = [
        {
            id: 0,
            weight: sessionStorage.getItem('petWeight'),
            weighingDate: null
        }
    ]
}

function fillIdentificationInfo(dto) {
    if (getStoredItem('chipNumber', STRING) === ''
        && getStoredItem('tattooNumber', STRING) === '') {
        return;
    }
    dto.identificationInfo = {
        id: 0,
        chipNumber: isEmptyStoreItem('chipNumber', STRING) ? null : sessionStorage.getItem('chipNumber'),
        tattooNumber: isEmptyStoreItem('tattooNumber', STRING) ? null : sessionStorage.getItem('tattooNumber')
    }
}

function fillThreatPreventionsBlock(dto) {
    dto.threatPreventions = [];

    if (!isEmptyStoreItem('fleaAndTickTreatmentDate', STRING)) {
        dto.threatPreventions.push({
            id: 0,
            preventionType: "FLEA_AND_TICK_TREATMENT",
            preventionDate: sessionStorage.getItem('fleaAndTickTreatmentDate')
        })
    }
    if (!isEmptyStoreItem('dewormingDate', STRING)) {
        dto.threatPreventions.push({
            id: 0,
            preventionType: "DEWORMING",
            preventionDate: sessionStorage.getItem('dewormingDate')
        })
    }
    if (!isEmptyStoreItem('complexVaccinationDate', STRING)) {
        dto.threatPreventions.push({
            id: 0,
            preventionType: "COMPLEX_VACCINATION",
            preventionDate: sessionStorage.getItem('complexVaccinationDate')
        })
    }
    if (!isEmptyStoreItem('rabiesVaccinationDate', STRING)) {
        dto.threatPreventions.push({
            id: 0,
            preventionType: "VACCINATION_AGAINST_RABIES",
            preventionDate: sessionStorage.getItem('rabiesVaccinationDate')
        })
    }
}

export function userHasPet(pets, id) {
    return hasValue(pets.filter(pet => pet.id === Number(id)))
}

/**
 * Отправляется запрос в БД на смену активного питомца пользователя
 * Результат записывается в стор
 */
export function updateActivePet(petId, token, dispatch, setErrorMessage) {
    const response = UserService.updateActivePet(Number(petId), token);
    response.then((resp) => {
        const data = resp.data.data;
        dispatch(setActivePet({activePet: data}));
    }, (error) => {
        setErrorMessage(error.response?.data?.messages ?
            error.response?.data?.messages?.ERROR[0] : error.message)
    })
}

/**
 * Обновить сведения о питомце вместе с породой
 */
export function updateActivePetWithBreed(i18n, setBreed, petId, token, dispatch, setErrorMessage) {
    const response = UserService.updateActivePet(Number(petId), token);
    response.then((resp) => {
        const data = resp.data.data;
        dispatch(setActivePet({activePet: data}));
        getBreedInfo(data.breedId, i18n, setBreed, setErrorMessage);
    }, (error) => {
        setErrorMessage(error.response?.data?.messages ?
            error.response?.data?.messages?.ERROR[0] : error.message)
    })
}

/**
 * Шаблонная часть по получению данных по актуальному питомцу в момент загрузки страницы
 */
export function getActivePetForUpdatePetInfo(activePetId, paramId, init, pets, setErrorMessage, token, dispatch) {
    if (activePetId === Number(paramId)) {
        init();
    } else {
        //если не нашли запрашиваемого питомца в списке питомцев
        if (!userHasPet(pets, paramId)) {
            setErrorMessage("Питомец на найден");
            return;
        }
        //если произошла валидная смена питомца, то мы запрашиваем все данные по нему
        updateActivePet(paramId, token, dispatch, setErrorMessage);
        init();
    }
}

/**
 * Зачистка стора после успешного сохранения питомца
 */
export function clearSessionDataForPet() {
    sessionStorage.removeItem('petBreed');
    sessionStorage.removeItem('petHomeName');
    sessionStorage.removeItem('petGender');
    sessionStorage.removeItem('livingEnvironment');
    sessionStorage.removeItem('livingConditions');
    sessionStorage.removeItem('petBirthDate');
    sessionStorage.removeItem('petWeight');
    sessionStorage.removeItem('isPetNeuter');
    sessionStorage.removeItem('nutritionType');
    sessionStorage.removeItem('chipNumber');
    sessionStorage.removeItem('tattooNumber');
    sessionStorage.removeItem('fleaAndTickTreatmentDate')
    sessionStorage.removeItem('dewormingDate')
    sessionStorage.removeItem('complexVaccinationDate')
    sessionStorage.removeItem('rabiesVaccinationDate')
    sessionStorage.removeItem('petAnimalKind')
    sessionStorage.removeItem('petFormValidatedSteps')
}