import React from 'react';

/**
 * Контент главной страницы для незарегистрированного пользователя
 */
const IndexContentUnregisterUser = () => {
    return (
        <div className="content">
            <div className="about-milkins">
                {/*<span>{t("milkins")}</span> {t("promo.about")}*/}
                <span>Система запущена в тестовом режиме</span>
            </div>

            {/*<PromoSection sectionTitle={t("promo.usefulForPet")}>
                <PromoParagraph title={t("promo.healthCard.title")}
                                content={<HealthCardPromoContent/>}/>
            </PromoSection>*/}
        </div>
    );
};

export default IndexContentUnregisterUser;